import React, { useState } from 'react';
import { Modal, Button, Form, Alert, ProgressBar } from 'react-bootstrap';

const TranscriptionModal = ({ show, onHide, audioFileName, recordingId, formData, onUploadComplete }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.name.endsWith('.srt')) {
      setFile(selectedFile);
      setError(null);
    } else {
      setFile(null);
      setError('Proszę wybrać plik SRT');
    }
  };

  const generateFileName = (originalName) => {
    return `${formData.metadata.signature}_${originalName}`;
  };

  const saveMetadata = async (srtFileName) => {
    const updatedFormData = {
      ...formData,
      technical: {
        ...formData.technical,
        storage: [...formData.technical.storage, srtFileName],
        transcripts: [
          ...formData.technical.transcripts,
          {
            audioFile: audioFileName,
            srtFile: srtFileName
          }
        ]
      }
    };

    const response = await fetch(`/api/recordings/${recordingId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedFormData),
      credentials: 'include'
    });

    if (!response.ok) throw new Error('Failed to update metadata');
    return response.json();
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Proszę wybrać plik SRT');
      return;
    }

    setUploading(true);
    setProgress(0);
    try {
      const formData = new FormData();
      const fileName = generateFileName(file.name);
      
      formData.append('file', file);
      formData.append('chunkIndex', '0');
      formData.append('totalChunks', '1');
      formData.append('fileName', fileName);

      // Upload pliku
      const uploadResponse = await fetch('/api/upload.php', {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      if (!uploadResponse.ok) throw new Error('Upload failed');

      setProgress(100);

      // Aktualizacja metadanych
      const result = await saveMetadata(fileName);
      onUploadComplete(result.data);
      onHide();
    } catch (err) {
      setError('Błąd podczas uploadu: ' + err.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Dodaj transkrypcję</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted">
          Dodajesz transkrypcję do pliku: <strong>{audioFileName}</strong>
        </p>
        <Form.Group className="mb-3">
          <Form.Label>Wybierz plik SRT</Form.Label>
          <Form.Control
            type="file"
            accept=".srt"
            onChange={handleFileSelect}
            disabled={uploading}
          />
        </Form.Group>
        {error && <Alert variant="danger">{error}</Alert>}
        {uploading && (
          <ProgressBar 
            now={progress} 
            label={`${progress}%`} 
            className="mt-3"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Anuluj
        </Button>
        <Button variant="primary" onClick={handleUpload} disabled={!file || uploading}>
          {uploading ? 'Wysyłanie...' : 'Wyślij'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TranscriptionModal;
