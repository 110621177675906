import React from 'react';
import { Button, Spinner, Badge } from 'react-bootstrap';
import { Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const TagRecordingsList = ({ recordings, recordingsDetails, isLoading }) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="text-center py-2">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Ładowanie wystąpień...</span>
        </Spinner>
      </div>
    );
  }

  if (!recordings || recordings.length === 0) {
    return (
      <div className="text-center text-muted py-2">
        Brak wystąpień tego tagu w nagraniach
      </div>
    );
  }

  const handleTimeClick = (signature, time, audioFile, transcriptFile) => {
    if (!audioFile || !transcriptFile) {
      console.error('Missing audio or transcript file');
      return;
    }

    navigate(`/recordings/${signature}/view`, {
      state: {
        initialTimestamp: time,
        showPlayer: true,
        audioFile,
        transcriptFile
      },
      replace: false // Ensure we're not replacing the current history entry
    });
  };

  return (
    <div className="list-group list-group-flush">
      {recordings.map((recording) => {
        const details = recordingsDetails[recording.id] || {};
        const metadata = details.metadata || {};
        const technical = details.technical || {};

        // Get the first audio file and its corresponding transcript
        const audioFile = technical.storage?.find(file => file.match(/\.(mp3|wav)$/i));
        const transcriptFile = technical.transcripts?.find(t => t.audioFile === audioFile)?.srtFile;

        return (
          <div key={recording.id} className="list-group-item py-2">
            <div className="d-flex align-items-center gap-3">
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fw-medium fs-5">
                    Rozmowa:<br/>
                    {metadata.interviewee?.name || 'Brak nazwy rozmówcy'}
                    <span className="text-muted ms-2 small">
                      {metadata.date ? new Date(metadata.date).toLocaleDateString('pl-PL') : 'Brak daty'}
                    </span>
                  </div>
                  <Button
                    variant="primary"
                    className="rounded-circle d-flex align-items-center justify-content-center p-3"
                    onClick={() => navigate(`/recordings/${metadata.signature}/view`)}
                    title="Podgląd nagrania"
                    style={{ width: '48px', height: '48px' }}
                  >
                    <FontAwesomeIcon icon={faPlay} />
                  </Button>
                </div>
                <div className="d-flex align-items-center gap-2 mt-1">
                  <span className="text-muted small">
                    {metadata.title || 'Brak tytułu'}
                  </span>
                  <span className="small text-muted">•</span>
                  {recording.timestamps.sort().map((time, idx) => (
                    <React.Fragment key={idx}>
                      <Badge
                        bg="light"
                        text="dark"
                        className="d-inline-flex align-items-center"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleTimeClick(
                            metadata.signature,
                            time,
                            audioFile,
                            transcriptFile
                          );
                        }}
                      >
                        <Clock size={12} className="me-1" />
                        {time}
                      </Badge>
                      {idx < recording.timestamps.length - 1 && (
                        <span className="small text-muted">•</span>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TagRecordingsList;
