import React, { useState, useEffect, useCallback, memo } from 'react';
import { Spinner, Tab, Tabs, ListGroup, Badge, Button } from 'react-bootstrap';
import { Clock, RotateCw } from 'lucide-react';

const TagItems = memo(({ items, activeTab, onTimeSelect }) => (
  <ListGroup variant="flush">
    {items.map(tag => (
      <ListGroup.Item key={tag.name}>
        <div className="fw-bold mb-2">
          <Badge bg={tag.type === 'keyword' ? 'info' : 'success'} className="me-2">
            {tag.type === 'keyword' ? 'Słowo kluczowe' : 'Osoba'}
          </Badge>
          {tag.name} ({tag.occurrences.length})
        </div>
        <div className="ms-3 d-flex flex-wrap gap-2 border-4">
          {tag.occurrences.sort((a, b) => a.start_time - b.start_time).map((occurrence, index) => (
            <div
              key={index}
              className="d-inline-flex align-items-center gap-2 text-primary"
              style={{ cursor: 'pointer' }}
              onClick={() => onTimeSelect(occurrence.start_time)}
              title="Kliknij, aby przejść do tego momentu"
            >
              <Clock size={14} />
              <span>{occurrence.formattedTime}</span>
            </div>
          ))}
        </div>
      </ListGroup.Item>
    ))}
    {items.length === 0 && (
      <ListGroup.Item className="text-muted text-center">
        Brak {activeTab === 'keywords' ? 'słów kluczowych' : 'osób'}
      </ListGroup.Item>
    )}
  </ListGroup>
));

const TagListAudio = ({ recordingId, transcriptFile, onTimeSelect, isEditing = false }) => {
  const [tags, setTags] = useState({ keywords: [], persons: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('keywords');
  const [lastLoadTime, setLastLoadTime] = useState(null);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const loadTags = useCallback(async () => {
    const currentTime = Date.now();
    if (lastLoadTime && (currentTime - lastLoadTime) < 6000) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`/api/tags.php?recording_id=${recordingId}&transcript_file=${transcriptFile}`);
      if (!response.ok) throw new Error('Nie udało się załadować tagów');
      const data = await response.json();

      const groupedTags = { keywords: [], persons: [] };
      const tagsByName = {};

      data.data.forEach(tag => {
        if (!tagsByName[tag.name]) {
          tagsByName[tag.name] = {
            id: tag.id,
            name: tag.name,
            type: tag.type,
            occurrences: []
          };
        }
        tagsByName[tag.name].occurrences.push({
          start_time: tag.start_time,
          formattedTime: formatTime(tag.start_time)
        });
      });

      Object.values(tagsByName).forEach(tag => {
        if (tag.type === 'keyword') {
          groupedTags.keywords.push(tag);
        } else if (tag.type === 'person') {
          groupedTags.persons.push(tag);
        }
      });

      groupedTags.keywords.sort((a, b) => a.name.localeCompare(b.name));
      groupedTags.persons.sort((a, b) => a.name.localeCompare(b.name));

      setTags(groupedTags);
      setLastLoadTime(currentTime);
      setError(null);
    } catch (err) {
      setError('Wystąpił błąd podczas ładowania tagów: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  }, [recordingId, transcriptFile, lastLoadTime]);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  useEffect(() => {
    let intervalId;
    if (isEditing) {
      intervalId = setInterval(loadTags, 6000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [loadTags, isEditing]);

  return (
    <div className="h-50">
      {isEditing && (
        <div className="p-3 border-bottom d-flex justify-content-end align-items-center">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={loadTags}
	    disabled={isLoading}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <RotateCw size={16} />
            )}
          </Button>
        </div>
      )}

      <div className="p-2">
        {error && (
          <div className="alert alert-danger">{error}</div>
        )}

        {isLoading && !tags.keywords.length && !tags.persons.length ? (
          <div className="text-center p-4">
            <Spinner animation="border" />
          </div>
        ) : (
          <Tabs
            activeKey={activeTab}
            onSelect={setActiveTab}
            className="mb-3"
          >
            <Tab eventKey="keywords" title={`Słowa kluczowe (${tags.keywords.length})`}>
              <div className="overflow-auto" style={{ maxHeight: '400px' }}>
                <TagItems
                  items={tags.keywords}
                  activeTab={activeTab}
                  onTimeSelect={onTimeSelect}
                />
              </div>
            </Tab>
            <Tab eventKey="persons" title={`Osoby (${tags.persons.length})`}>
              <div className="overflow-auto" style={{ maxHeight: '400px' }}>
                <TagItems
                  items={tags.persons}
                  activeTab={activeTab}
                  onTimeSelect={onTimeSelect}
                />
              </div>
            </Tab>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default memo(TagListAudio);
