import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = () => {
  const location = useLocation();
  const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

  useEffect(() => {
    // Nie ładuj GA jeśli nie ma ID
    if (!measurementId) {
      console.warn('Google Analytics Measurement ID is not defined');
      return;
    }

    // Load Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', measurementId, {
      debug_mode: process.env.NODE_ENV === 'development'
    });

    // Track page views
    const trackPageView = () => {
      gtag('event', 'page_view', {
        page_path: location.pathname,
        page_location: window.location.href,
        page_title: document.title
      });
    };

    // Track initial page view
    trackPageView();

    return () => {
      document.head.removeChild(script);
    };
  }, [measurementId, location]);

  return null;
};

export default GoogleAnalytics;
