import React, { useState, useRef } from 'react';
import { Card, ProgressBar, Alert, Form, Button } from 'react-bootstrap';
import { Upload } from 'lucide-react';

const RecordingUploader = ({ recordingId, formData, onUploadComplete }) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const uploadStats = useRef({
    startTime: null,
    uploadSpeeds: [],
    lastChunkTime: null,
    totalUploaded: 0
  });

  const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB
  const SPEED_WINDOW_SIZE = 5;

  const formatRemainingTime = (seconds) => {
    if (seconds < 60) {
      return `${Math.round(seconds)} s`;
    } else if (seconds < 3600) {
      const minutes = Math.ceil(seconds / 60);
      return `${minutes} ${minutes === 1 ? 'm' : 'm'}`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.ceil((seconds % 3600) / 60);
      return `${hours} ${hours === 1 ? 'h' : 'h'} ${minutes} ${minutes === 1 ? 'm' : 'm'}`;
    }
  };

  const calculateRemainingTime = (currentChunk, totalChunks, chunkSize, file) => {
    const stats = uploadStats.current;
    const currentTime = Date.now();

    if (stats.lastChunkTime) {
      const timeDiff = (currentTime - stats.lastChunkTime) / 1000;
      const bytesUploaded = chunkSize;
      const speed = bytesUploaded / timeDiff;

      stats.uploadSpeeds.push(speed);
      if (stats.uploadSpeeds.length > SPEED_WINDOW_SIZE) {
        stats.uploadSpeeds.shift();
      }
    }

    stats.lastChunkTime = currentTime;
    stats.totalUploaded += chunkSize;

    if (stats.uploadSpeeds.length >= 2) {
      const avgSpeed = stats.uploadSpeeds.reduce((a, b) => a + b, 0) / stats.uploadSpeeds.length;
      const remainingSize = file.size - stats.totalUploaded;
      const remainingSeconds = remainingSize / avgSpeed;
      return formatRemainingTime(Math.ceil(remainingSeconds * 1.1));
    }

    return 'obliczanie...';
  };

  const generateFileName = (originalName) => {
    return `${formData.metadata.signature}_${originalName}`;
  };

  const saveMetadata = async (fileName) => {
    const updatedFormData = {
      ...formData,
      technical: {
        ...formData.technical,
        storage: [...formData.technical.storage, fileName]
      }
    };

    const response = await fetch(`/api/recordings/${recordingId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedFormData),
      credentials: 'include'
    });

    if (!response.ok) throw new Error('Failed to update metadata');
    return response.json();
  };

  const uploadChunk = async (chunk, index, totalChunks, file) => {
    const formData = new FormData();
    formData.append('file', chunk);
    formData.append('chunkIndex', index);
    formData.append('totalChunks', totalChunks);
    formData.append('fileName', generateFileName(file.name));

    const response = await fetch('/api/upload.php', {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    if (!response.ok) throw new Error('Upload failed');
    return response.json();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setSelectedFile(null);
      return;
    }

    if (!file.type.match('audio/(mpeg|wav)')) {
      setError('Dozwolone są tylko pliki MP3 i WAV');
      setSelectedFile(null);
      return;
    }

    setSelectedFile(file);
    setError(null);
    setProgress(0);
    setRemainingTime(null);
  };

  const handleUpload = async () => {
    if (!selectedFile || isUploading) return;

    const totalChunks = Math.ceil(selectedFile.size / CHUNK_SIZE);
    setProgress(0);
    setError(null);
    setRemainingTime('obliczanie...');
    setIsUploading(true);

    uploadStats.current = {
      startTime: Date.now(),
      uploadSpeeds: [],
      lastChunkTime: null,
      totalUploaded: 0
    };

    try {
      for (let i = 0; i < totalChunks; i++) {
        const chunk = selectedFile.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
        await uploadChunk(chunk, i, totalChunks, selectedFile);

        const currentProgress = ((i + 1) / totalChunks) * 100;
        setProgress(currentProgress);
        const remaining = calculateRemainingTime(i + 1, totalChunks, chunk.size, selectedFile);
        setRemainingTime(remaining);
      }

      const fileName = generateFileName(selectedFile.name);
      const result = await saveMetadata(fileName);
      onUploadComplete(result.data);
      setRemainingTime(null);
      setSelectedFile(null);
      // Reset file input
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) fileInput.value = '';
    } catch (err) {
      setError('Błąd: ' + err.message);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Card className="mt-3 mb-3 bg-secondary-subtle">
      <Card.Body>
        <h4>Dodaj plik nagrania</h4>
	  <Form.Group className="mb-3">
  <Form.Label>Plik audio (MP3/WAV)</Form.Label>
  <div className="d-flex flex-column">
    <Form.Control
      type="file"
      accept=".mp3,.wav"
      onChange={handleFileSelect}
      className="mb-3"
      disabled={isUploading}
    />
    <Button
      variant="primary"
      onClick={handleUpload}
      disabled={!selectedFile || isUploading}
      style={{ whiteSpace: 'nowrap', alignSelf: 'flex-end' }}
    >
        <Upload size={16} className="me-2" />
      {isUploading ? 'Przesyłanie...' : 'Prześlij'}
    </Button>
  </div>
</Form.Group>

        {progress > 0 && progress < 100 && (
          <div className="mb-3">
            <ProgressBar now={progress} label={`${Math.round(progress)}%`} />
            {remainingTime && (
              <small className="text-muted mt-1 d-block">
                Pozostały czas: {remainingTime}
              </small>
            )}
          </div>
        )}
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
      </Card.Body>
    </Card>
  );
};

export default RecordingUploader;
