import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ProofreadingStatus from './ProofreadingStatus';

const MetadataFields = ({ formData, setFormData, mode = 'create' }) => {
  return (
    <>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <label className="form-label">Tytuł</label>
            <input
              type="text"
              className="form-control"
              value={formData.metadata.title}
              onChange={e => setFormData({
                ...formData,
                metadata: { ...formData.metadata, title: e.target.value }
              })}
              required
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <label className="form-label">Data</label>
            <input
              type="date"
              className="form-control"
              value={formData.metadata.date}
              onChange={e => setFormData({
                ...formData,
                metadata: { ...formData.metadata, date: e.target.value }
              })}
              required
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <label className="form-label">Lokalizacja</label>
            <input
              type="text"
              className="form-control"
              value={formData.metadata.location}
              onChange={e => setFormData({
                ...formData,
                metadata: { ...formData.metadata, location: e.target.value }
              })}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <label className="form-label">Czas trwania</label>
            <input
              type="text"
              className="form-control"
              value={formData.metadata.duration}
              onChange={e => setFormData({
                ...formData,
                metadata: { ...formData.metadata, duration: e.target.value }
              })}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <label className="form-label rozmowca">Rozmówca</label>
            <input
              type="text"
              className="form-control"
              value={formData.metadata.interviewee.name}
              onChange={e => setFormData({
                ...formData,
                metadata: {
                  ...formData.metadata,
                  interviewee: {
                    ...formData.metadata.interviewee,
                    name: e.target.value
                  }
                }
              })}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <label className="form-label rozmowca">Prowadzący wywiad</label>
            <input
              type="text"
              className="form-control"
              value={formData.metadata.interviewer}
              onChange={e => setFormData({
                ...formData,
                metadata: { ...formData.metadata, interviewer: e.target.value }
              })}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <label className="form-label">Biogram rozmówcy</label>
            <textarea
              className="form-control"
              rows="3"
              value={formData.metadata.interviewee.biography}
              onChange={e => setFormData({
                ...formData,
                metadata: {
                  ...formData.metadata,
                  interviewee: {
                    ...formData.metadata.interviewee,
                    biography: e.target.value
                  }
                }
              })}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <label className="form-label">Streszczenie</label>
            <textarea
              className="form-control"
              rows="4"
              value={formData.description.summary || ''}
              onChange={e => setFormData({
                ...formData,
                description: {
                  ...formData.description,
                  summary: e.target.value
                }
              })}
            />
          </div>
        </Col>
      </Row>
      {mode === 'edit' && (
        <Row className="mt-4">
          <Col md={12}>
            <div className="mb-3">
              <label className="form-label">Status korekty</label>
              <ProofreadingStatus
                status={formData.proofreading?.status || ''}
                onChange={(newStatus) => setFormData({
                  ...formData,
                  proofreading: {
                    ...formData.proofreading,
                    status: newStatus
                  }
                })}
                disabled={!formData.proofreading?.email}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MetadataFields;
