import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import { X } from 'lucide-react';

const RecordingInfoModal = ({ show, onHide, recording }) => {
  const getPhotoUrl = () => {
    if (recording?.metadata?.interviewee?.photo) {
      return `/storage/recordings/${recording.metadata.signature}/${recording.metadata.interviewee.photo}`;
    }
    return null;
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header className="border-bottom">
        <Modal.Title as="h5">Informacje o rozmowie</Modal.Title>
        <Button
          variant="link"
          onClick={onHide}
          className="p-0 ms-2 text-muted"
          style={{ fontSize: '20px' }}
        >
          <X size={24} />
        </Button>
      </Modal.Header>
      <Modal.Body className="p-4 fs-5">
        <div className="d-flex flex-column gap-4">
          <div className="d-flex gap-4 align-items-start">
            {recording?.metadata?.interviewee?.photo && (
              <Image
                src={getPhotoUrl()}
                alt={`Zdjęcie: ${recording.metadata.interviewee?.name}`}
                className="rounded-circle object-cover shadow"
                style={{
                  width: '120px',
                  height: '120px',
                  objectFit: 'cover'
                }}
              />
            )}
            <div className="flex-grow-1">
              <h3 className="mb-2">{recording?.metadata?.interviewee?.name}</h3>
              <h5 className="text-muted mb-2">{recording?.metadata?.title}</h5>
              <p className="text-muted mb-0">
                Data nagrania: {new Date(recording?.metadata?.date).toLocaleDateString('pl-PL')}
              </p>
            </div>
          </div>

          {recording?.metadata?.interviewee?.biography && (
            <div>
              <h5 className="mb-3">Biogram rozmówcy</h5>
              <p className="mb-0">{recording.metadata.interviewee.biography}</p>
            </div>
          )}

          {recording?.description?.summary && (
            <div>
              <h5 className="mb-3">Streszczenie rozmowy</h5>
              <p className="mb-0">{recording.description.summary}</p>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RecordingInfoModal;
