import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Trash, FileText } from 'lucide-react';
import RecordingUploader from './RecordingUploader';
import TranscriptionModal from './TranscriptionModal';

const RecordingFiles = ({ recordingId, formData, setFormData }) => {
  const [showTranscriptionModal, setShowTranscriptionModal] = useState(false);
  const [selectedAudioFile, setSelectedAudioFile] = useState(null);

  const handleDeleteFile = async (fileName) => {
    if (!window.confirm('Czy na pewno chcesz usunąć ten plik?')) return;

    try {
      const response = await fetch(`/api/recordings/${recordingId}/file/${fileName}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fileName }),
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Failed to delete file');

      setFormData(prev => ({
        ...prev,
        technical: {
          ...prev.technical,
          storage: prev.technical.storage.filter(f => f !== fileName),
          transcripts: prev.technical.transcripts.filter(t =>
            t.audioFile !== fileName && t.srtFile !== fileName
          )
        }
      }));
    } catch (err) {
      console.error('Error deleting file:', err);
    }
  };

  const handleAddTranscription = (audioFile) => {
    setSelectedAudioFile(audioFile);
    setShowTranscriptionModal(true);
  };

  const getTranscriptionForAudio = (audioFile) => {
    return formData.technical.transcripts.find(t => t.audioFile === audioFile);
  };

  const isAudioFile = (fileName) => {
    return fileName.match(/\.(mp3|wav)$/i);
  };

  const groupedFiles = formData.technical.storage.reduce((acc, fileName) => {
    if (isAudioFile(fileName)) {
      const transcription = getTranscriptionForAudio(fileName);
      acc.push({
        audioFile: fileName,
        transcription: transcription
      });
    } else if (!formData.technical.transcripts.some(t => t.srtFile === fileName)) {
      acc.push({
        otherFile: fileName
      });
    }
    return acc;
  }, []);

  return (
    <>
      <Card className="mb-3 bg-secondary-subtle">
        <Card.Body>
          <h4>Pliki nagrań</h4>
          {groupedFiles.length > 0 ? (
            <ul className="list-group">
              {groupedFiles.map((group, index) => (
                <li key={group.audioFile || group.otherFile} className="list-group-item p-0 border-0 mb-2">
                  <div className="border rounded">
                    <div className="p-2 d-flex justify-content-between align-items-center">
                      <span className="me-auto">
                        {group.audioFile ? (
                          <span className="text-primary fw-bold">{group.audioFile}</span>
                        ) : (
                          group.otherFile
                        )}
                      </span>
                      <div className="d-flex gap-2">
                        {group.audioFile && !group.transcription && (
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => handleAddTranscription(group.audioFile)}
                            title="Dodaj transkrypcję"
                          >
                            <FileText size={16} />
                          </Button>
                        )}
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => handleDeleteFile(group.audioFile || group.otherFile)}
                        >
                          <Trash size={16} />
                        </Button>
                      </div>
                    </div>
                    {group.transcription && (
                      <div className="border-top bg-light p-2 ps-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <small className="text-muted">
                            Transkrypcja: {group.transcription.srtFile}
                          </small>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleDeleteFile(group.transcription.srtFile)}
                          >
                            <Trash size={16} />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted">Brak dodanych plików</p>
          )}
        </Card.Body>
      </Card>
      <RecordingUploader
        recordingId={recordingId}
        formData={formData}
        onUploadComplete={setFormData}
      />
      <TranscriptionModal
        show={showTranscriptionModal}
        onHide={() => setShowTranscriptionModal(false)}
        audioFileName={selectedAudioFile}
        recordingId={recordingId}
        formData={formData}
        onUploadComplete={setFormData}
      />
    </>
  );
};

export default RecordingFiles;
