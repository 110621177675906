import React, { useState, useEffect, useCallback, memo } from 'react';
import { Modal, Button, Form, Badge, Spinner } from 'react-bootstrap';
import { X, Tag, Search } from 'lucide-react';

const SearchInput = memo(({ value, onChange }) => (
  <div className="position-relative mb-3 w-50">
    <Form.Control
      autoFocus
      type="text"
      placeholder="Wyszukaj ..."
      value={value}
      onChange={onChange}
      className="pe-4"
    />
    <Search
      size={16}
      className="position-absolute"
      style={{
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#6c757d'
      }}
    />
  </div>
));

const TagsList = memo(({ items, selectedTags, onToggle, searchQuery }) => {
  const filteredItems = items
    .filter(tag => !searchQuery || tag.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}));

  return (
    <div
      className="d-flex flex-wrap m-2 p-2 gap-2"
      style={{
        maxHeight: '200px',
        overflowY: 'auto',
        padding: '8px'
      }}
    >
      {filteredItems.map(tag => (
        <Badge
          key={tag.id}
          bg={selectedTags.includes(tag.id) ? 'primary' : 'light'}
          text={selectedTags.includes(tag.id) ? 'light' : 'dark'}
          className="p-2 cursor-pointer user-select-none"
          style={{
            cursor: 'pointer',
            border: `3px solid ${tag.type === 'person' ? '#299764' : '#0dcaf0'}`
          }}
          onClick={() => onToggle(tag.id)}
        >
          {tag.name}
        </Badge>
      ))}
      {filteredItems.length === 0 && (
        <div className="text-muted">
          {searchQuery ? 'Brak wyników dla podanej frazy' : 'Brak dostępnych tagów'}
        </div>
      )}
    </div>
  );
});

const AddTagsModal = ({
  show,
  onHide,
  recordingId,
  transcriptFile,
  startTime,
  currentTags = []
}) => {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [newTagName, setNewTagName] = useState('');
  const [newTagType, setNewTagType] = useState('');

  useEffect(() => {
    const loadTags = async () => {
      try {
        const response = await fetch('/api/tags.php');
        if (!response.ok) throw new Error('Nie udało się załadować tagów');
        const data = await response.json();
        setTags(data.data);
        setSelectedTags(currentTags.map(tag => tag.id));
      } catch (err) {
        setError('Błąd ładowania tagów: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (show) {
      loadTags();
      setSearchQuery('');
      setNewTagName('');
      setNewTagType('');
    }
  }, [show, currentTags]);

  const handleSaveAll = async (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      let tagsToAssign = [...selectedTags];

      // Jeśli jest nowy tag do dodania
      if (newTagName.trim() && newTagType) {
        const createResponse = await fetch('/api/tags.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: newTagName,
            type: newTagType
          })
        });

        if (!createResponse.ok) throw new Error('Błąd podczas dodawania tagu');
        const result = await createResponse.json();
        const newTag = { id: result.id, name: newTagName, type: newTagType };
        tagsToAssign.push(newTag.id);
        setTags(prev => [...prev, newTag]);
      }

      // Usuń wszystkie obecne tagi
      for (const tag of currentTags) {
        await fetch('/api/tags.php', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            tag_id: tag.id,
            recording_id: recordingId,
            transcript_file: transcriptFile,
            start_time: startTime
          })
        });
      }

      // Przypisz wybrane tagi
      for (const tagId of tagsToAssign) {
        await fetch('/api/tags.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            tag_id: tagId,
            recording_id: recordingId,
            transcript_file: transcriptFile,
            start_time: startTime
          })
        });
      }

      setNewTagName('');
      setNewTagType('');
      onHide(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTagToggle = useCallback((tagId) => {
    setSelectedTags(prev =>
      prev.includes(tagId)
        ? prev.filter(id => id !== tagId)
        : [...prev, tagId]
    );
  }, []);

  return (
    <Modal show={show} onHide={() => onHide(false)} size="lg" className="fade">
      <Modal.Header closeButton className="bg-secondary-subtle">
        <Modal.Title>Słowa kluczowe i osoby</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-secondary-subtle">
        {error && (
          <div className="alert alert-danger mb-3">
            {error}
          </div>
        )}

        {isLoading ? (
          <div className="text-center p-3">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <SearchInput value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

            <div className="d-flex gap-2 pb-2 border-bottom border-primary">
              <Badge bg="info" className="text-dark p-2">
                Słowa kluczowe
              </Badge>
              <Badge bg="success" className="text-white p-2">
                Osoby
              </Badge>
            </div>

            <TagsList
              items={tags}
              selectedTags={selectedTags}
              onToggle={handleTagToggle}
              searchQuery={searchQuery}
            />

            <Form className="mt-2 border-top border-primary pt-2" onSubmit={handleSaveAll}>
              <Form.Group className="d-flex gap-2">
                <Form.Control
                  type="text"
                  placeholder="Dodaj..."
                  value={newTagName}
                  onChange={(e) => setNewTagName(e.target.value)}
                  className="w-50"
                />
                <div className="d-flex gap-2">
                  <Badge
                    bg={newTagType === 'keyword' ? 'info' : 'light'}
                    text={newTagType === 'keyword' ? 'dark' : 'secondary'}
                    className="p-2 user-select-none"
                    style={{ cursor: 'pointer', minWidth: '120px', textAlign: 'center' }}
                    onClick={() => setNewTagType('keyword')}
                  >
                    Słowo kluczowe
                  </Badge>
                  <Badge
                    bg={newTagType === 'person' ? 'success' : 'light'}
                    text={newTagType === 'person' ? 'light' : 'secondary'}
                    className="p-2 user-select-none"
                    style={{ cursor: 'pointer', minWidth: '120px', textAlign: 'center' }}
                    onClick={() => setNewTagType('person')}
                  >
                    Osoba
                  </Badge>
                </div>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="bg-secondary-subtle">
        <Button variant="secondary" onClick={() => onHide(false)}>
          <X size={16} className="me-2" />
          Anuluj
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveAll}
          disabled={isSubmitting || (newTagName.trim() && !newTagType)}
        >
          {isSubmitting ? (
            <>
              <Spinner size="sm" className="me-2" />
              Zapisywanie...
            </>
          ) : (
            <>
              <Tag size={16} className="me-2" />
              {newTagName.trim() ? 'Dodaj i przypisz' : 'Przypisz zaznaczone'}
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTagsModal;
