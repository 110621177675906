import React, { useState } from 'react';
import { Form, Button, Alert, Card, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function PasswordReset() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [showResetForm, setShowResetForm] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleRequestReset = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/auth-api/request-password-reset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (response.ok) {
        setShowResetForm(true);
        setMessage(data.message);
        setError('');
      } else {
        setError(data.error);
      }
    } catch (err) {
      setError('Nieudana zmiana hasła');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/auth-api/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, token })
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message);
        setError('');
        setTimeout(() => navigate('/login'), 1500);
      } else {
        setError(data.error);
      }
    } catch (err) {
      setError('Nieudana zmiana hasła');
    }
  };

  return (
    <Container className="vh-100">
      <Row className="h-100 align-items-center justify-content-center">
        <Col xs={12} sm={8} md={6} lg={4}>
          <Card className="shadow-lg border-0">
            <Card.Header className="bg-primary text-white text-center py-3">
              <h3 className="mb-0">Zmień hasło</h3>
            </Card.Header>
            <Card.Body className="p-4">
              {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="success">{message}</Alert>}

              {!showResetForm ? (
                <Form onSubmit={handleRequestReset}>
                  <Form.Group className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Podaj email"
                    />
                  </Form.Group>
                  <Button type="submit" className="w-100">Zmień hasło</Button>
                </Form>
              ) : (
                <Form onSubmit={handleResetPassword}>
                  <Form.Group className="mb-4">
                    <Form.Label>Kod weryfikacyjny</Form.Label>
                    <Form.Control
                      type="text"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                      required
                      placeholder="Podaj kod weryfikacyjny"
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Nowe hasło</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      minLength={8}
                      placeholder="Podaj nowe hasło"
                    />
                  </Form.Group>
                  <Button type="submit" className="w-100">Zmień hasło</Button>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default PasswordReset;
