import React, { useEffect, useRef } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Icon } from 'ol/style';
import 'ol/ol.css';

const LibraryMap = () => {
  const mapRef = useRef();
  const mapInstanceRef = useRef(null);

  useEffect(() => {
    // Coordinates of the library
    const libraryCoordinates = fromLonLat([20.733840, 52.471371]);

    // Create marker feature
    const markerFeature = new Feature({
      geometry: new Point(libraryCoordinates)
    });

    // Create vector layer for marker
    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [markerFeature]
      }),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: '/marker-icon.png',
          scale: 0.08//, // Zmniejszona skala dla lepszej widoczności
          //color: '#e3342f' // Czerwony kolor markera
        })
      })
    });

    // Create map instance
    const map = new Map({
      target: mapRef.current,
      layers: [
        // Base map layer
        new TileLayer({
          source: new OSM()
        }),
        vectorLayer
      ],
      view: new View({
        center: libraryCoordinates,
        zoom: 17
      })
    });

    mapInstanceRef.current = map;

    // Cleanup
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.setTarget(null);
      }
    };
  }, []);

  return (
    <div
      ref={mapRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative'
      }}
    />
  );
};

export default LibraryMap;
