import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Play, Pause, SkipBack, SkipForward } from 'lucide-react';

const AudioPlayerControls = ({
  isPlaying,
  currentTime,
  duration,
  onPlayPause,
  onSkip,
  onTimeUpdate,
  waveformVisible = true,
  soundRef
}) => {
  const formatTime = (seconds) => {
    if (!seconds) return "00:00:00";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleProgressClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const clickPosition = (e.clientX - rect.left) / rect.width;
    onTimeUpdate(clickPosition * duration);
  };

  const handleProgressTouch = (e) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    const touch = e.touches[0];
    const clickPosition = (touch.clientX - rect.left) / rect.width;
    onTimeUpdate(clickPosition * duration);
  };

  return (
    <Card className="bg-dark text-white mb-4">
      <Card.Body>
        <div
          className="progress mb-3"
          style={{ height: '10px', cursor: 'pointer' }}
          onClick={handleProgressClick}
          onTouchStart={handleProgressTouch}
          role="progressbar"
          aria-valuenow={(currentTime / duration) * 100}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="progress-bar"
            style={{ width: `${(currentTime / duration) * 100}%` }}
          />
        </div>

        <div className="d-flex justify-content-between mb-3">
          <small>{formatTime(currentTime)}</small>
          <small>{formatTime(duration)}</small>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center gap-3">
          <div className="d-flex gap-3 order-1 justify-content-center w-100">
            <Button
              variant="outline-light"
              className="rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: '48px', height: '48px', padding: 0, touchAction: 'manipulation' }}
              onClick={() => onSkip(-10)}
            >
              <SkipBack size={20} />
            </Button>
            <Button
              variant="outline-light"
              className="rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: '48px', height: '48px', padding: 0, touchAction: 'manipulation' }}
              onClick={onPlayPause}
            >
              {isPlaying ? <Pause size={24} /> : <Play size={24} />}
            </Button>
            <Button
              variant="outline-light"
              className="rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: '48px', height: '48px', padding: 0, touchAction: 'manipulation' }}
              onClick={() => onSkip(10)}
            >
              <SkipForward size={20} />
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AudioPlayerControls;
