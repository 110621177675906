// Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { aboutContent, aboutPartnersContent } from '../content/about';
import LibraryMap from './LibraryMap';

export function Footer() {
  return (
    <footer className="bg-secondary-subtle mt-5 py-4">
      <Container>
        <Row>
          <Col md={4}>
            <h5>Biblioteka Publiczna Gminy Pomiechówek</h5>
            <p>ul. Jana Kilińskiego 3<br />05-180 Pomiechówek<br />Tel: +48 22 785-46-97</p>
          </Col>
          <Col md={4}>
            <h5>Nawigacja</h5>
            <ul className="list-unstyled">
              <li><Link to="/" className="text-decoration-none">Start</Link></li>
              <li><Link to="/about" className="text-decoration-none">O projekcie</Link></li>
              <li><Link to="/contact" className="text-decoration-none">Kontakt</Link></li>
              <li><Link to="/privacy" className="text-decoration-none">Polityka prywatności</Link></li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Śledź nas</h5>
            <div className="d-flex gap-3">
              <a href="https://facebook.com" className="text-decoration-none" target="_blank" rel="noopener noreferrer">Facebook</a>
              <a href="https://instagram.com" className="text-decoration-none" target="_blank" rel="noopener noreferrer">Instagram</a>
            </div>
	            <img src="/img/pbhm-logo-3.svg" className="w-50 img-fluid" alt="PBHM Logo" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <p className="mb-0">© {new Date().getFullYear()} Pomiechowska Biblioteka Historii Mówionej. Wszelkie prawa zastrzeżone.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

// About.js
export function About() {
  return (
    <Container className="py-5">
      <h1 className="mb-4">O projekcie</h1>
      <Row>
        <Col lg={8}>
          <div dangerouslySetInnerHTML={{ __html: aboutContent }} />
          <div dangerouslySetInnerHTML={{ __html: aboutPartnersContent }} />
        </Col>
      </Row>
    </Container>
  );
}

// Contact.js
export function Contact() {
  return (
    <Container className="py-5">
      <h1 className="mb-4">Kontakt</h1>
      <Row>
        <Col md={6}>
          <h2 className="h4">Dane kontaktowe</h2>
          <p>
            <strong>Adres:</strong><br />
            Biblioteka Publiczna Gminy Pomiechówek<br />
            ul. Jana Kilińskiego 3<br />
            05-180 Pomiechówek
          </p>
          <p>
            <strong>Telefon:</strong> +48 22 785-46-97<br />
            <strong>Email:</strong> biblioteka@pomiechowek.pl
          </p>
        </Col>
        <Col md={6}>
          <div style={{ height: '400px' }}>
            <LibraryMap />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

// Privacy.js
export function Privacy() {
  return (
    <Container className="py-5">
      <h1 className="mb-4">Polityka prywatności</h1>
      <div>
        <h2 className="h4">1. Informacje ogólne</h2>
        <p>Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych użytkowników Pomiechowskiej Biblioteki Historii Mówionej.</p>
        
        <h2 className="h4 mt-4">2. Administrator danych</h2>
        <p>Administratorem danych jest Pomiechowska Biblioteka Historii Mówionej z siedzibą w Pomiechówku.</p>
        
        <h2 className="h4 mt-4">3. Cel przetwarzania danych</h2>
        <p>Dane osobowe przetwarzane są w celu:</p>
        <ul>
          <li>Realizacji projektu historii mówionej</li>
          <li>Archiwizacji materiałów historycznych</li>
          <li>Kontaktu z uczestnikami projektu</li>
        </ul>
        
        <h2 className="h4 mt-4">4. Prawa użytkowników</h2>
        <p>Każdy użytkownik ma prawo do:</p>
        <ul>
          <li>Dostępu do swoich danych</li>
          <li>Sprostowania danych</li>
          <li>Usunięcia danych</li>
          <li>Ograniczenia przetwarzania</li>
        </ul>
      </div>
    </Container>
  );
}
