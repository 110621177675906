import React, { useState } from 'react';
import { Form, Button, Alert, Card, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [apiMessage, setApiMessage] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/auth-api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, firstName, lastName, password })
      });

      const data = await response.json();

      if (response.ok) {
        setVerificationSent(true);
        setError('');
        setApiMessage(data.message);
      } else {
        setError(data.error);
        setApiMessage('');
      }
    } catch (err) {
      setError('Rejestracja nieudana. Spróbuj ponownie.');
      setApiMessage('');
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/auth-api/verify-registration', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token })
      });

      const data = await response.json();

      if (response.ok) {
        setError('');
        setApiMessage(data.message || 'Rejestracja udana!');
        setTimeout(() => navigate('/login'), 1500);
      } else {
        setError(data.error);
        setApiMessage('');
      }
    } catch (err) {
      setError('Weryfikacja nieudana. Spróbuj ponownie.');
      setApiMessage('');
    }
  };

  return (
    <Container className="vh-100">
      <Row className="h-100 align-items-center justify-content-center">
        <Col xs={12} sm={8} md={6} lg={4}>
          <Card className="shadow-lg border-0">
            <Card.Header className="bg-primary text-white text-center py-3">
              <h3 className="mb-0">Rejestracja</h3>
            </Card.Header>
            <Card.Body className="p-4">
              {error && <Alert variant="danger">{error}</Alert>}
              {apiMessage && <Alert variant="success">{apiMessage}</Alert>}

              {!verificationSent ? (
                <Form onSubmit={handleRegister}>
                  <Form.Group className="mb-3">
                    <Form.Label>Imię</Form.Label>
                    <Form.Control
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      placeholder="Podaj imię"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Nazwisko</Form.Label>
                    <Form.Control
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      placeholder="Podaj nazwisko"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Podaj email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      minLength={8}
                      placeholder="Podaj hasło (min. 8 znaków)"
                    />
                  </Form.Group>

                  <Button type="submit" className="w-100">Register</Button>
                </Form>
              ) : (
                <Form onSubmit={handleVerification}>
                  <Form.Group className="mb-4">
                    <Form.Label>Podaj kod weryfikacyjny, który wysłaliśmy mailem</Form.Label>
                    <Form.Control
                      type="text"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                      required
                      placeholder="Wprowadź kod"
                    />
                  </Form.Group>

                  <Button type="submit" className="w-100">Zweryfikuj</Button>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
