import React, { useState, useEffect } from 'react';
import { Form, Spinner, Modal, InputGroup, Badge } from 'react-bootstrap';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const TagsBrowser = () => {
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [recordings, setRecordings] = useState([]);
  const [recordingsDetails, setRecordingsDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoadingRecordings, setIsLoadingRecordings] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadAllTags();
  }, []);

  const loadAllTags = async () => {
    try {
      const [keywordsResponse, personsResponse] = await Promise.all([
        fetch('/api/tags.php?type=keyword'),
        fetch('/api/tags.php?type=person')
      ]);

      if (!keywordsResponse.ok || !personsResponse.ok) {
        throw new Error('Nie udało się załadować tagów');
      }

      const [keywordsData, personsData] = await Promise.all([
        keywordsResponse.json(),
        personsResponse.json()
      ]);

      const allTags = [
        ...keywordsData.data.map(tag => ({ ...tag, type: 'keyword' })),
        ...personsData.data.map(tag => ({ ...tag, type: 'person' }))
      ].sort((a, b) => a.name.localeCompare(b.name));

      setTags(allTags);
    } catch (err) {
      setError('Wystąpił błąd podczas ładowania tagów: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const fetchRecordingDetails = async (recordingId) => {
    try {
      const response = await fetch(`/api/recordings/${recordingId}`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Nie udało się pobrać szczegółów nagrania');
      const result = await response.json();
      return result.data;
    } catch (err) {
      console.error('Błąd podczas pobierania szczegółów nagrania:', err);
      return null;
    }
  };

  const handleTagClick = async (tag) => {
    setSelectedTag(tag);
    setIsLoadingRecordings(true);

    try {
      const response = await fetch(`/api/tags.php?tag_id=${tag.id}`);
      if (!response.ok) throw new Error('Nie udało się załadować nagrań');
      const data = await response.json();

      const groupedRecordings = data.data.reduce((acc, item) => {
        if (!acc[item.recording_id]) {
          acc[item.recording_id] = {
            id: item.recording_id,
            timestamps: []
          };
        }
        acc[item.recording_id].timestamps.push(formatTime(item.start_time));
        return acc;
      }, {});

      const recordingsList = Object.values(groupedRecordings);
      setRecordings(recordingsList);

      const details = {};
      await Promise.all(
        recordingsList.map(async (recording) => {
          const recordingDetails = await fetchRecordingDetails(recording.id);
          if (recordingDetails) {
            details[recording.id] = recordingDetails;
          }
        })
      );
      setRecordingsDetails(details);
    } catch (err) {
      setError('Wystąpił błąd: ' + err.message);
    } finally {
      setIsLoadingRecordings(false);
    }
  };

  const handleTimeClick = (recordingId, timestamp) => {
    const [hours, minutes, seconds] = timestamp.split(':').map(Number);
    const timeInSeconds = hours * 3600 + minutes * 60 + seconds;

    navigate(`/library/recording/${recordingId}`, {
      state: { initialTime: timeInSeconds }
    });
  };

  const getFilteredTags = () => {
    return tags.filter(tag =>
      !searchQuery || tag.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const calculateFontSize = (count) => {
    if (!count) return 'fs-5';
    if (count > 16) return 'fs-1';
    if (count > 12) return 'fs-2';
    if (count > 8) return 'fs-3';
    if (count > 4) return 'fs-4';
    if (count > 2) return 'fs-5';
    return 'fs-5';
  };

  const groupTagsByFirstChar = (tags) => {
    return tags.reduce((groups, tag) => {
      const firstChar = tag.name.charAt(0).toUpperCase();
      const groupChar = /^[0-9]/.test(firstChar) ? '#' : firstChar;

      if (!groups[groupChar]) {
        groups[groupChar] = [];
      }
      groups[groupChar].push(tag);
      return groups;
    }, {});
  };

  const getTagStyle = (tag) => {
    const baseStyle = {
      cursor: 'pointer',
      transition: 'all 0.2s ease'
    };

    if (tag.type === 'person') {
      return {
        ...baseStyle,
        color: '#2E7D32' // ciemny zielony
      };
    }

    return {
      ...baseStyle,
      color: '#1976D2' // niebieski
    };
  };

  const renderTagList = () => {
    const filteredTags = getFilteredTags();
    const groupedTags = groupTagsByFirstChar(filteredTags);
    const sortedGroups = Object.keys(groupedTags).sort((a, b) => {
      if (a === '#') return -1;
      if (b === '#') return 1;
      return a.localeCompare(b);
    });

    return (
      <div className="row fs-4"><div className="col-lg-1"></div>
        <div className="col-lg-10 mx-auto d-flex flex-wrap gap-3 align-items-baseline">
          {sortedGroups.map((group) => (
            <React.Fragment key={group}>
              <span className="text-muted fw-bold align-self-center px-2 border-end">
                {group}
              </span>
              {groupedTags[group].map((tag) => (
                <span
                  key={tag.id}
                  onClick={() => handleTagClick(tag)}
                  className={`${calculateFontSize(tag.recordings_count)}`}
                  style={getTagStyle(tag)}
                >
                  {tag.name}
                  {tag.recordings_count > 0 && (
                    <span className="text-muted ms-1">({tag.recordings_count})</span>
                  )}
                </span>
              ))}
            </React.Fragment>
          ))}
          {sortedGroups.length === 0 && (
            <div className="text-muted">
              {searchQuery ? 'Brak wyników dla podanej frazy' : 'Brak dostępnych tagów'}
            </div>
          )}
        </div><div className="col-lg-1"></div>
      </div>
    );
  };

  const TagRecordingsList = ({ recordings, recordingsDetails, isLoading }) => {
    if (isLoading) {
      return (
        <div className="text-center p-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Ładowanie...</span>
          </Spinner>
        </div>
      );
    }

    if (recordings.length === 0) {
      return <p className="text-muted text-center">Brak nagrań z tym tagiem</p>;
    }

    return (
      <div className="list-group">
        {recordings.map(recording => {
          const details = recordingsDetails[recording.id];
          if (!details) return null;

          return (
            <div key={recording.id} className="list-group-item">
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h6 className="mb-1">{details.metadata.interviewee?.name}</h6>
                  <p className="mb-1 text-muted small">{details.metadata.title}</p>
                  <div>
                    {recording.timestamps.map((timestamp, index) => (
                      <Badge
                        key={index}
                        bg="primary"
                        className="me-1 cursor-pointer"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleTimeClick(recording.id, timestamp)}
                      >
                        {timestamp}
                      </Badge>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="text-center mt-4">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Ładowanie...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div className="alert alert-danger">
          {error}
        </div>
      )}

<div className="mb-4">
 <InputGroup className="mb-3">
   <Form.Control
     type="text"
     placeholder="Szukaj ..."
     value={searchQuery}
     onChange={(e) => setSearchQuery(e.target.value)} 
   />
   <InputGroup.Text>
     <Search size={20} />
   </InputGroup.Text>
 </InputGroup>

 <div className="d-flex gap-3 fs-4 align-items-center">
   <div style={{ color: '#1976D2' }} className="fw-black badge border d-flex align-items-center gap-2">
     <span>●</span>
     <span>Słowa kluczowe</span>
   </div>
   <div style={{ color: '#2E7D32' }} className="fw-black badge border d-flex align-items-center gap-2">
     <span>●</span>
     <span>Osoby</span> 
   </div>
 </div>
</div>

      {renderTagList()}

      <Modal show={selectedTag !== null} onHide={() => setSelectedTag(null)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex align-items-center gap-2">
              <span className={`badge ${selectedTag?.type === 'keyword' ? 'bg-primary' : 'bg-success'}`}>
                {selectedTag?.type === 'keyword' ? 'Słowo kluczowe' : 'Osoba'}
              </span>
              <span>{selectedTag?.name}</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TagRecordingsList
            recordings={recordings}
            recordingsDetails={recordingsDetails}
            isLoading={isLoadingRecordings}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TagsBrowser;
