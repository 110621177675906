import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Image, Card, Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { Play, Search, FileText } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import RecordingInfoModal from './RecordingInfoModal';
import TagsBrowser from './TagsBrowser';

const ListeningModule = () => {
  const [recordings, setRecordings] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredRecordings, setFilteredRecordings] = useState([]);
  const [selectedRecording, setSelectedRecording] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [activeTab, setActiveTab] = useState('recordings');
  const navigate = useNavigate();

  useEffect(() => {
    fetchRecordings();
  }, []);

  useEffect(() => {
    const filtered = recordings.filter(recording =>
      recording.metadata.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      recording.metadata.interviewee?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredRecordings(filtered);
  }, [searchQuery, recordings]);

  const fetchRecordings = async () => {
    try {
      const response = await fetch('/api/recordings');
      const data = await response.json();
      setRecordings(data.data);
      setFilteredRecordings(data.data);
    } catch (error) {
      console.error('Error fetching recordings:', error);
    }
  };

  const handlePlayRecording = (recording) => {
    navigate(`/library/recording/${recording.metadata.signature}`);
  };

  const handleShowInfo = (recording) => {
    setSelectedRecording(recording);
    setShowInfoModal(true);
  };

  const getPhotoUrl = (recording) => {
    if (recording.metadata.interviewee?.photo) {
      return `/storage/recordings/${recording.metadata.signature}/${recording.metadata.interviewee.photo}`;
    }
    return null;
  };

  const RecordingsView = () => (
    <>
      <div className="position-relative mb-4">

 <InputGroup className="mb-3">
   <Form.Control
     type="text"
     placeholder="Szukaj ..."
     value={searchQuery}
     onChange={(e) => setSearchQuery(e.target.value)} 
   />
   <InputGroup.Text>
     <Search size={20} />
   </InputGroup.Text>
 </InputGroup>

	  {/*
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder="Szukaj nagrań..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Search
          size={20}
          className="position-absolute"
          style={{ right: '15px', top: '50%', transform: 'translateY(-50%)', color: '#6c757d' }}
        />*/}
      </div>

      <Row>
        {filteredRecordings.map(recording => (
          <Col md={6} lg={4} className="mb-4" key={recording.metadata.signature}>
            <Card className="h-100">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-3 flex-grow-1">
                    {recording.metadata.interviewee?.photo && (
                      <Image
                        src={getPhotoUrl(recording)}
                        alt={`Zdjęcie: ${recording.metadata.interviewee?.name}`}
                        className="rounded-circle object-cover shadow-sm"
                        style={{
                          width: '64px',
                          height: '64px',
                          objectFit: 'cover'
                        }}
                      />
                    )}
                    <div className="flex-grow-1">
                      <h5 className="mb-2">{recording.metadata.interviewee?.name}</h5>
                      <p className="text-muted mb-2">{recording.metadata.title}</p>
                      <small className="text-muted">
                        Data nagrania: {new Date(recording.metadata.date).toLocaleDateString('pl-PL')}
                      </small>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <Button
                      variant="primary"
                      className="rounded-circle d-flex align-items-center justify-content-center"
                      style={{ width: '48px', height: '48px', padding: 0 }}
                      onClick={() => handleShowInfo(recording)}
                      title="Informacje"
                    >
                      <FileText size={24} />
                    </Button>
                    <Button
                      variant="primary"
                      className="rounded-circle d-flex align-items-center justify-content-center"
                      style={{ width: '48px', height: '48px', padding: 0 }}
                      onClick={() => handlePlayRecording(recording)}
                      title="Odtwórz"
                    >
                      <Play size={24} />
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h2 className="mb-4">Historia mówiona</h2>
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-4"
          >
            <Tab eventKey="recordings" title="Nagrania">
              <RecordingsView />
            </Tab>
            <Tab eventKey="tags" title="Indeksy">
              <div>
                <TagsBrowser />
              </div>
            </Tab>
          </Tabs>
        </Col>
      </Row>

      <RecordingInfoModal
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
        recording={selectedRecording}
      />
    </Container>
  );
};

export default ListeningModule;
