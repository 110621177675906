import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Container, Button, Card, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import MetadataFields from './MetadataFields';
import RecordingFiles from './RecordingFiles';
import IntervieweePhotoUploader from './IntervieweePhotoUploader';

const RecordingForm = ({ mode = 'create' }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    metadata: {
      signature: '',
      title: '',
      date: '',
      location: '',
      duration: '',
      interviewer: '',
      interviewee: {
        name: '',
        photo: '',
        biography: ''
      }
    },
    proofreading: {
	    email: '',
	    status: ''
    },
    description: {
      summary: '',
      timeline: [],
      indexes: {
        geographical: [],
        personal: [],
        subject: []
      }
    },
    technical: {
      format: '',
      storage: [],
      transcripts: []
    },
    access: {
      restrictions: '',
      rights: ''
    }
  });

  const fetchRecording = useCallback(async () => {
    try {
      const response = await fetch(`/api/recordings/${id}`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to fetch recording');
      const result = await response.json();
      setFormData(result.data);
      setError(null);
    } catch (err) {
      setError('Error fetching recording: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (mode === 'edit' && id) {
      fetchRecording();
    } else {
      setIsLoading(false);
    }
  }, [mode, id, fetchRecording]);

// W RecordingForm.js
const handleSubmit = async (e) => {
  e.preventDefault();
  const method = mode === 'create' ? 'POST' : 'PUT';
  const url = mode === 'create' ? '/api/recordings' : `/api/recordings/${id}`;

  try {
    const response = await fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
      credentials: 'include'
    });

    if (!response.ok) throw new Error('Failed to save recording');
    
    // Zmiana nawigacji
    if (mode === 'create') {
      const result = await response.json();
      navigate(`/recordings/`); // Zakładając, że API zwraca ID nowej rozmowy
    } else {
      navigate(`/recordings/${id}/view`);
    }
  } catch (err) {
    setError('Error saving recording: ' + err.message);
  }
};


  const AAhandleSubmit = async (e) => {
    e.preventDefault();
    const method = mode === 'create' ? 'POST' : 'PUT';
    const url = mode === 'create' ? '/api/recordings' : `/api/recordings/${id}`;

    try {
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Failed to save recording');
      navigate('/recordings');
    } catch (err) {
      setError('Error saving recording: ' + err.message);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>

  <div className="d-flex justify-content-between align-items-center mb-3">
    <h2 className="mb-0">{mode === 'create' ? 'Nowa rozmowa' : 'Edycja rozmowy'}</h2>
    <div className="d-flex gap-2">
      <Button
        variant="outline-secondary"
        onClick={() => navigate(mode === 'create' ? '/recordings' : `/recordings/${id}/view`)}
      >
        Anuluj
      </Button>
      <Button type="submit" form="recordingForm">
        Zapisz
      </Button>
    </div>
  </div>
  
  {error && <Alert variant="danger">{error}</Alert>}
  <form id="recordingForm" onSubmit={handleSubmit}>

        <Row>
          <Col md={6}>
            <Card className="mb-3 bg-secondary-subtle">
              <Card.Body>
                <MetadataFields formData={formData} setFormData={setFormData} />
              </Card.Body>
            </Card>
          </Col>

          {mode === 'edit' && id && (
            <Col md={6}>
              <IntervieweePhotoUploader
                recordingId={id}
                formData={formData}
                setFormData={setFormData}
              />
              <RecordingFiles
                recordingId={id}
                formData={formData}
                setFormData={setFormData}
              />
            </Col>
          )}
        </Row>

        <div className="d-flex gap-2 justify-content-end">
<Button 
  variant="outline-secondary" 
  onClick={() => navigate(mode === 'create' ? '/recordings' : `/recordings/${id}/view`)}
>
  Anuluj
</Button>
          <Button type="submit" variant="primary">
            Zapisz
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default RecordingForm;
