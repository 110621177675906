import React, { useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Play, Pause, SkipBack, SkipForward, Volume2 } from 'lucide-react';
import AudioWaveform from './AudioWaveform';

const AudioPlayer = ({
  audioRef,
  audioUrl,
  isPlaying,
  currentTime,
  duration,
  volume,
  playbackRate,
  onPlayPause,
  onTimeUpdate,
  onLoadedMetadata,
  onVolumeChange,
  onPlaybackRateChange,
  onProgressClick,
  onSkipAudio,
  showTagsListButton = false,
  onShowTagsList
}) => {
  const playbackRates = [0.5, 0.75, 1, 1.25, 1.5, 2];

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play().catch(error => {
          console.error('Error playing audio:', error);
          onPlayPause(false);
        });
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, onPlayPause, audioRef]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
      audioRef.current.playbackRate = playbackRate;
    }
  }, [volume, playbackRate, audioRef]);

  return (
    <Card className="bg-dark text-white">
      <Card.Body>
        <audio
          ref={audioRef}
          src={audioUrl}
          onTimeUpdate={onTimeUpdate}
          onLoadedMetadata={onLoadedMetadata}
          onEnded={() => onPlayPause(false)}
        />

        <div
          className="progress mb-1"
          style={{ height: '10px', cursor: 'pointer' }}
          onClick={onProgressClick}
        >
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${(currentTime / duration) * 100}%` }}
            aria-valuenow={(currentTime / duration) * 100}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>

        <div className="d-flex justify-content-between mb-1">
          <small>{formatTime(currentTime)}</small>
          <small>{formatTime(duration)}</small>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          {/* Left section with volume control */}
          <div className="d-flex align-items-center gap-2">
            <AudioWaveform isPlaying={isPlaying} audioRef={audioRef} />
            <Volume2 size={20} className="text-muted" />
            <input
              type="range"
              className="form-range"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={(e) => onVolumeChange(parseFloat(e.target.value))}
              style={{ width: '80px' }}
            />
          </div>

          {/* Center section with playback controls */}
          <div className="d-flex justify-content-center gap-3">
            <Button
              variant="outline-light"
  className="rounded-circle d-flex align-items-center justify-content-center"
  style={{ width: '48px', height: '48px', padding: 0 }}
              size="sm"
              onClick={() => onSkipAudio(-10)}
              title="Przewiń 10s wstecz"
            >
              <SkipBack size={20} />
            </Button>
	  <Button
  variant="outline-light"
  size="lg"
  className="rounded-circle d-flex align-items-center justify-content-center"
  style={{ width: '48px', height: '48px', padding: 0 }}
  onClick={() => onPlayPause(!isPlaying)}
>
  {isPlaying ? <Pause size={24} /> : <Play size={24} />}
</Button>
            <Button
              variant="outline-light"
  className="rounded-circle d-flex align-items-center justify-content-center"
  style={{ width: '48px', height: '48px', padding: 0 }}
              size="sm"
              onClick={() => onSkipAudio(10)}
              title="Przewiń 10s do przodu"
            >
              <SkipForward size={20} />
            </Button>
          </div>

          {/* Right section with playback rate control */}
          <div className="d-flex align-items-center gap-2">
            <select
              className="form-select form-select-sm bg-dark text-white border-light"
              value={playbackRate}
              onChange={(e) => onPlaybackRateChange(parseFloat(e.target.value))}
              style={{ width: '80px' }}
            >
              {playbackRates.map(rate => (
                <option key={rate} value={rate}>
                  {rate}x
                </option>
              ))}
            </select>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AudioPlayer;
