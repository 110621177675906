import React from 'react';
import { Carousel, Container, Row, Col, Card, Button } from 'react-bootstrap';

function Public() {
  return (
    <>
      {/* Hero Section */}
      <div className="bg-primary text-white py-5 mb-5 px-5">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1 className="display-4 fw-bold">Pomiechowska Biblioteka Historii Mówionej</h1>
              <p className="lead">
                Odkrywamy i zachowujemy historię naszej gminy poprzez wspomnienia jej mieszkańców.
              </p>
              <Button variant="light" size="lg" className="mt-3">
                Podziel się swoją historią
              </Button>
            </Col>
            <Col md={6} className="text-center">
              <img
                src="/img/299769833_772851977651752_8551081860543016558_n.jpg"
                alt="Stare zdjęcie Pomiechówka"
                className="img-fluid rounded shadow"
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Historie Section */}
      <Container className="mb-5 px-5">
        <h2 className="text-center mb-4">Historie naszych mieszkańców</h2>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 rounded"
              src="/img/186215259_486213349648951_2120885602353230150_n.jpg"
              alt="Historie mieszkańców"
            />
            <Carousel.Caption className="bg-primary bg-opacity-75">
              <h3>Wspomnienia z dawnego Pomiechówka</h3>
              <p>Posłuchaj opowieści najstarszych mieszkańców o życiu w naszej gminie.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 rounded"
              src="/img/193493084_10226795463819499_1568404570067888317_n.jpg"
              alt="Lokalne tradycje"
            />
            <Carousel.Caption className="bg-primary bg-opacity-75">
              <h3>Lokalne tradycje i zwyczaje</h3>
              <p>Poznaj jak wyglądało życie codzienne, święta i uroczystości.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 rounded"
              src="/img/200614138_2084073295082576_6257114356442554170_n.jpg"
              alt="Archiwalne zdjęcia"
            />
            <Carousel.Caption className="bg-primary bg-opacity-75">
              <h3>Archiwalne fotografie</h3>
              <p>Zobacz unikalne zdjęcia z rodzinnych albumów mieszkańców.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>

      {/* O Projekcie Section */}
      <Container className="mb-5">
        <Row>
          <Col md={6}>
            <h2 className="mb-4">O naszym projekcie</h2>
            <p className="lead">
              Pomiechowska Biblioteka Historii Mówionej to projekt mający na celu zachowanie
              pamięci o historii naszej gminy poprzez nagrywanie wspomnień jej mieszkańców.
            </p>
            <p>
              Gromadzimy wywiady, zdjęcia i dokumenty, które pozwalają nam lepiej poznać
              i zrozumieć historię naszej małej ojczyzny. Każda opowieść jest cennym
              świadectwem przeszłości i buduje naszą wspólną tożsamość.
            </p>
            <Button variant="primary" className="mt-3">Dowiedz się więcej</Button>
          </Col>
          <Col md={6}>
            <Card className="h-100 border-0 shadow-sm">
              <Card.Body>
                <h3 className="mb-4">Dołącz do projektu</h3>
                <ul className="list-unstyled">
                  <li className="mb-3">✓ Podziel się swoimi wspomnieniami</li>
                  <li className="mb-3">✓ Udostępnij rodzinne fotografie</li>
                  <li className="mb-3">✓ Zostań wolontariuszem</li>
                  <li className="mb-3">✓ Pomóż w zbieraniu historii</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Kolekcje Section */}
      <Container className="mb-5">
        <h2 className="text-center mb-4">Nasze kolekcje</h2>
        <Row>
          <Col md={4} className="mb-4">
            <Card className="h-100 shadow-sm">
              <Card.Body className="text-center">
                <div className="display-1 mb-3">🎙️</div>
                <Card.Title>Wywiady</Card.Title>
                <Card.Text>
                  Nagrania wspomnień mieszkańców gminy Pomiechówek.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="h-100 shadow-sm">
              <Card.Body className="text-center">
                <div className="display-1 mb-3">📷</div>
                <Card.Title>Fotografie</Card.Title>
                <Card.Text>
                  Archiwalne zdjęcia z rodzinnych albumów.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="h-100 shadow-sm">
              <Card.Body className="text-center">
                <div className="display-1 mb-3">📜</div>
                <Card.Title>Dokumenty</Card.Title>
                <Card.Text>
                  Historyczne dokumenty związane z gminą.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Contact Section */}
      <Container className="mb-5">
        <Row className="bg-light rounded p-5 align-items-center">
          <Col md={8}>
            <h2>Masz historię do opowiedzenia?</h2>
            <p className="lead mb-0">
              Skontaktuj się z nami i pomóż nam zachować historię Pomiechówka.
            </p>
          </Col>
          <Col md={4} className="text-md-end">
            <Button variant="primary" size="lg">
              Skontaktuj się
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Public;
