import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Navbar, Nav, Container, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

function Navigation({ isAuthenticated, userRole, onLogout }) {
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [expanded, setExpanded] = useState(false);

  const translateRole = (role) => {
    const roles = {
      'admin': 'administrator',
      'editor': 'redaktor',
      'user': 'użytkownik'
    };
    return roles[role] || role;
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetch('/auth-api/check-session', {
        credentials: 'include'
      })
      .then(res => res.json())
      .then(data => {
        if (data.email) {
          setUserEmail(data.email);
        }
        if (data.name) {
          setUserName(data.name);
        }
      });
    }
  }, [isAuthenticated]);

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      const success = await onLogout();
      if (success) {
        setExpanded(false);
        navigate('/', { replace: true });
      }
    } finally {
      setIsLoggingOut(false);
    }
  };

  const handleNavigation = () => {
    setExpanded(false);
  };

  return (
    <>
      <Container fluid>
        <Container>
          <Row className="justify-content-end">
            <Col className="offset-xl-8 xl-4 offset-lg-7 lg-5 offset-md-4 md-8">
              <img
                className="img-fluid"
                style={{aspectRatio: "auto"}}
                alt="KPO"
                src="/img/KPO.png"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Navbar
        bg="aprimary"
        variant="dark"
        expand="lg"
        className="prod-or-dev"
        expanded={expanded}
        onToggle={setExpanded}
      >
        <Container>
          <Navbar.Brand as={Link} to="/" onClick={handleNavigation}>
            <img
              src="/img/pbhm-logo-out1.png"
              height="30px"
              className="d-inline-block align-top me-2"
              alt="MyApp Logo"
            />{process.env.REACT_APP_NAV_BRAND_TEXT}
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/" onClick={handleNavigation}>Start</Nav.Link>
              {isAuthenticated && (
                <Nav.Link as={Link} to="/library" onClick={handleNavigation}>Historia mówiona</Nav.Link>
              )}
              {isAuthenticated && (userRole === 'editor' || userRole === 'admin') && (
                <>
		      {/*<Nav.Link as={Link} to="/recordings" onClick={handleNavigation}>Rozmowy</Nav.Link>
                  <Nav.Link as={Link} to="/tags" onClick={handleNavigation}>Tagi</Nav.Link>*/}
    <Nav.Link as={Link} to="/recordings" onClick={handleNavigation} className="d-none d-lg-block">Rozmowy</Nav.Link>
    <Nav.Link as={Link} to="/tags" onClick={handleNavigation} className="d-none d-lg-block">Tagi</Nav.Link>
                </>
              )}
            </Nav>

            <Nav>
              <Nav.Link as={Link} to="/about" onClick={handleNavigation}>O projekcie</Nav.Link>
              <Nav.Link as={Link} to="/contact" onClick={handleNavigation}>Kontakt</Nav.Link>
              <Navbar.Text>|</Navbar.Text>
              {!isAuthenticated ? (
                <>
                  <Nav.Link as={Link} to="/register" onClick={handleNavigation}>Rejestracja</Nav.Link>
                  <Nav.Link as={Link} to="/login" onClick={handleNavigation}>Logowanie</Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link className="text-light">
                    <span className="me-3">{userName}</span>
                    <span>{translateRole(userRole)}</span>
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      handleNavigation();
                      handleLogout();
                    }}
                    disabled={isLoggingOut}
                  >
                    {isLoggingOut ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        Wylogowywanie...
                      </>
                    ) : (
                      'Wyloguj'
                    )}
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;
