import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Container, Table, Button, Alert, Form, InputGroup } from 'react-bootstrap';
import { Lock, Plus, Edit, Trash, Eye, Search, ChevronUp, ChevronDown, Music, FileText, X, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const RecordingsList = ({ userRole }) => {
  const [recordings, setRecordings] = useState([]);
  const [filteredRecordings, setFilteredRecordings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });
  const navigate = useNavigate();

  const filterRecordings = useCallback(() => {
    let filtered = [...recordings];

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(recording =>
        recording.metadata.interviewee.name?.toLowerCase().includes(searchLower) ||
        recording.metadata.title?.toLowerCase().includes(searchLower) ||
        recording.metadata.date?.toLowerCase().includes(searchLower)
      );
    }

    if (sortConfig.key) {
      filtered.sort((a, b) => {
        let aValue, bValue;

        switch (sortConfig.key) {
          case 'name':
            aValue = a.metadata.interviewee.name || '';
            bValue = b.metadata.interviewee.name || '';
            break;
          case 'title':
            aValue = a.metadata.title || '';
            bValue = b.metadata.title || '';
            break;
          case 'date':
            aValue = a.metadata.date || '';
            bValue = b.metadata.date || '';
            break;
          default:
            return 0;
        }

        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setFilteredRecordings(filtered);
  }, [recordings, searchTerm, sortConfig]);

const renderProofreadingStatus = (status) => {
  switch(status) {
    case 'in_progress':
      return <span className="text-warning">W trakcie</span>;
    case 'completed':
      return <span className="text-success">Zakończona</span>;
    default:
      return <span className="text-secondary">Brak</span>;
  }
};

  const fetchRecordings = async () => {
    try {
      const response = await fetch('/api/recordings', {
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const recordingsArray = result.data || [];
      setRecordings(recordingsArray);
      setError(null);
    } catch (err) {
      setError('Błąd podczas pobierania nagrań: ' + err.message);
      setRecordings([]);
    }
  };

  useEffect(() => {
    fetchRecordings();
  }, []);

  useEffect(() => {
    filterRecordings();
  }, [filterRecordings]);

  const handleDelete = async (id) => {
    if (!window.confirm('Czy na pewno chcesz usunąć to nagranie?')) {
      return;
    }

    try {
      const response = await fetch(`/api/recordings/${id}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await fetchRecordings();
    } catch (err) {
      setError('Błąd podczas usuwania nagrania: ' + err.message);
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ChevronUp size={16} className="opacity-25" />;
    }
    return sortConfig.direction === 'asc' ?
      <ChevronUp size={16} /> :
      <ChevronDown size={16} />;
  };

  const renderColumnHeader = (label, key) => (
    <th
      className="cursor-pointer user-select-none"
      onClick={() => handleSort(key)}
      style={{ cursor: 'pointer' }}
    >
      <div className="d-flex align-items-center gap-2">
        {label}
        {renderSortIcon(key)}
      </div>
    </th>
  );

  const renderStatus = (exists) => (
    <span className={`d-flex align-items-center gap-1 ${exists ? 'text-success' : 'text-danger'}`}>
      {exists ? <Check size={16} /> : <X size={16} />}
      {exists ? 'Jest' : 'Brak'}
    </span>
  );

  const hasAudioFile = (recording) => {
    return recording.technical?.storage?.some(file => file.endsWith('.mp3')) || false;
  };

  const hasTranscriptFile = (recording) => {
    return recording.technical?.storage?.some(file => file.endsWith('.srt')) || false;
  };

  const renderActionButtons = (recording) => {
    return (
      <div className="d-flex gap-2">
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => navigate(`/recordings/${recording.metadata.signature}/view`)}
          title="Podgląd"
        >
          <Eye size={16} />
        </Button>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => navigate(`/recordings/${recording.metadata.signature}/edit`)}
          title="Edycja"
        >
          <Edit size={16} />
        </Button>
        {userRole === 'admin' && (
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => handleDelete(recording.metadata.signature)}
            title="Usuń"
          >
            <Trash size={16} />
          </Button>
        )}
      </div>
    );
  };

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Lista rozmów</h2>
        <Button onClick={() => navigate('/recordings/new')} className="d-flex gap-2 align-items-center">
          <Plus size={16} /> Dodaj rozmowę
        </Button>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      <Row>
        <Col md={4}>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <Search size={16} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Szukaj..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="text-center" style={{ width: '50px' }}>Lp.</th>
            {renderColumnHeader('Rozmówca', 'name')}
            {renderColumnHeader('Tytuł', 'title')}
            {renderColumnHeader('Data', 'date')}
            <th>
              <div className="d-flex align-items-center gap-2">
                <Music size={16} /> Audio
              </div>
            </th>
            <th>
              <div className="d-flex align-items-center gap-2">
                <FileText size={16} /> Transkrypcja
              </div>
            </th>
            <th>Akcje</th>
	    <th>Status korekty</th>
            <th>Blokada</th>
          </tr>
        </thead>
        <tbody>
          {filteredRecordings.length > 0 ? (
            filteredRecordings.map((recording, index) => (
<tr key={recording.metadata.signature}>
  <td className="text-center">{index + 1}</td>
  <td className="rozmowca fs-5">{recording.metadata.interviewee.name}</td>
  <td>{recording.metadata.title}</td>
  <td>{new Date(recording.metadata.date).toLocaleDateString('pl-PL')}</td>
  <td>{renderStatus(hasAudioFile(recording))}</td>
  <td>{renderStatus(hasTranscriptFile(recording))}</td>
  <td>{renderActionButtons(recording)}</td>
  <td>{renderProofreadingStatus(recording.proofreading?.status)}</td>
  <td>
    {recording.proofreading?.email && (
      <small className="locked text-danger d-flex align-items-center gap-1">
        <Lock size={16} />
        {recording.proofreading.email}
      </small>
    )}
  </td>
</tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                {searchTerm ? 'Brak wyników dla podanych kryteriów' : 'Brak nagrań'}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default RecordingsList;
