import React, { useEffect, useRef } from 'react';

const AudioWaveform = ({ isPlaying, audioRef }) => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);

  useEffect(() => {
    if (!audioRef.current || !canvasRef.current) return;

    // Initialize audio context and analyser if not already created
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      sourceRef.current = audioContextRef.current.createMediaElementSource(audioRef.current);
      sourceRef.current.connect(analyserRef.current);
      analyserRef.current.connect(audioContextRef.current.destination);
    }

    const analyser = analyserRef.current;
    analyser.fftSize = 64;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    const draw = () => {
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;
      
      analyser.getByteFrequencyData(dataArray);
      
      ctx.fillStyle = 'rgb(20, 20, 20)';
      ctx.fillRect(0, 0, WIDTH, HEIGHT);
      
      const barWidth = WIDTH / bufferLength * 1.5;
      let x = 0;
      
      for (let i = 0; i < bufferLength; i++) {
        const barHeight = (dataArray[i] / 255) * HEIGHT;
        const y = HEIGHT - barHeight;
        
        ctx.fillStyle = `rgb(65, 145, 255)`;
        ctx.fillRect(x, y, barWidth, barHeight);
        
        x += barWidth + 1;
      }
      
      if (isPlaying) {
        animationRef.current = requestAnimationFrame(draw);
      }
    };

    if (isPlaying) {
      draw();
    } else if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
      // Clear canvas when paused
      ctx.fillStyle = 'rgb(20, 20, 20)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isPlaying, audioRef]);

  return (
    <canvas 
      ref={canvasRef} 
      width={70} 
      height={30} 
      style={{ 
        backgroundColor: 'rgb(20, 20, 20)',
        borderRadius: '4px'
      }} 
    />
  );
};

export default AudioWaveform;
