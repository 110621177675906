import React, { useState } from 'react';
import { Card, Form, Button, Image, Alert } from 'react-bootstrap';
import { Trash } from 'lucide-react';

const IntervieweePhotoUploader = ({ recordingId, formData, setFormData }) => {
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const STORAGE_BASE_URL = '/storage/recordings';

  const generateFileName = (originalName) => {
    const extension = originalName.split('.').pop().toLowerCase();
    return `${formData.metadata.signature}_photo.${extension}`;
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.match('image/(jpeg|png|gif)')) {
      setError('Dozwolone są tylko pliki JPG, PNG i GIF');
      event.target.value = '';
      return;
    }

    // Sprawdź rozmiar pliku (max 50MB)
    const maxSize = 50 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      setError('Maksymalny rozmiar pliku to 5MB');
      event.target.value = '';
      return;
    }

    setError(null);
    setIsUploading(true);

    try {
      const formDataUpload = new FormData();
      formDataUpload.append('file', file);
      formDataUpload.append('fileName', generateFileName(file.name));

      const response = await fetch('/api/img-upload.php', {
        method: 'POST',
        body: formDataUpload,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Upload failed');
      }

      const result = await response.json();

      // Aktualizuj dane formularza
      const updatedFormData = {
        ...formData,
        metadata: {
          ...formData.metadata,
          interviewee: {
            ...formData.metadata.interviewee,
            photo: result.fileName
          }
        }
      };

      // Zapisz zmiany w API
      const saveResponse = await fetch(`/api/recordings/${recordingId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedFormData),
        credentials: 'include'
      });

      if (!saveResponse.ok) {
        const saveErrorData = await saveResponse.json();
        throw new Error(saveErrorData.error || 'Failed to update metadata');
      }

      setFormData(updatedFormData);
      event.target.value = '';

    } catch (err) {
      setError('Błąd: ' + err.message);
      event.target.value = '';
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeletePhoto = async () => {
    if (!formData.metadata.interviewee.photo) return;

    if (!window.confirm('Czy na pewno chcesz usunąć to zdjęcie?')) return;

    try {
      const fileName = formData.metadata.interviewee.photo;
      const response = await fetch(`/api/recordings/${recordingId}/file/${fileName}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fileName }),
        credentials: 'include'
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete file');
      }

      const updatedFormData = {
        ...formData,
        metadata: {
          ...formData.metadata,
          interviewee: {
            ...formData.metadata.interviewee,
            photo: ''
          }
        }
      };

      // Zapisz zmiany w API
      const saveResponse = await fetch(`/api/recordings/${recordingId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedFormData),
        credentials: 'include'
      });

      if (!saveResponse.ok) {
        const saveErrorData = await saveResponse.json();
        throw new Error(saveErrorData.error || 'Failed to update metadata');
      }

      setFormData(updatedFormData);
      setError(null);

    } catch (err) {
      setError('Błąd usuwania zdjęcia: ' + err.message);
    }
  };

  const getPhotoUrl = (fileName) => {
    return `${STORAGE_BASE_URL}/${recordingId}/${fileName}`;
  };

  const hasPhoto = Boolean(formData.metadata.interviewee.photo);
  const photoUrl = hasPhoto ? getPhotoUrl(formData.metadata.interviewee.photo) : null;

  return (
    <Card className="mb-3 bg-secondary-subtle">
      <Card.Body>
        <h4>Zdjęcie rozmówcy</h4>
        {hasPhoto ? (
          <div className="text-center mb-3">
            <Image
              src={photoUrl}
              alt={`Zdjęcie: ${formData.metadata.interviewee.name || 'rozmówcy'}`}
              fluid
              style={{
                maxHeight: '300px',
                objectFit: 'contain',
                backgroundColor: '#fff',
                padding: '10px',
                borderRadius: '4px'
              }}
              className="mb-3"
            />
            <div>
              <Button
                variant="outline-danger"
                onClick={handleDeletePhoto}
                className="d-flex gap-2 align-items-center mx-auto"
                disabled={isUploading}
              >
                <Trash size={16} />
                Usuń zdjęcie
              </Button>
            </div>
          </div>
        ) : (
          <Form.Group>
            <Form.Label>Wybierz zdjęcie (JPG, PNG, GIF, max 5MB)</Form.Label>
            <div className="d-flex flex-column">
              <Form.Control
                type="file"
                accept="image/jpeg,image/png,image/gif"
                onChange={handleFileSelect}
                className="mb-3"
                disabled={isUploading}
              />
              {isUploading && (
                <div className="text-center mb-3">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Przesyłanie...</span>
                  </div>
                  <div className="mt-2 text-muted">Przesyłanie zdjęcia...</div>
                </div>
              )}
            </div>
          </Form.Group>
        )}
        {error && (
          <Alert variant="danger" className="mt-3" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};

export default IntervieweePhotoUploader;
