import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Clock, CheckCircle, AlertCircle } from 'lucide-react';

const STATUSES = [
  {
    value: '',
    label: 'Brak',
    icon: AlertCircle,
    variant: 'secondary'
  },
  {
    value: 'in_progress',
    label: 'W trakcie',
    icon: Clock,
    variant: 'warning'
  },
  {
    value: 'completed',
    label: 'Zakończona',
    icon: CheckCircle,
    variant: 'success'
  }
];

const getSelectedStatus = (currentStatus) =>
  STATUSES.find(s => s.value === currentStatus) || STATUSES[0];

const ProofreadingStatus = ({ status, onChange, disabled }) => {
  const selectedStatus = getSelectedStatus(status);
  const StatusIcon = selectedStatus.icon;

  return (
    <div className="d-flex align-items-center gap-2">
      <span className="text-secondary">Status korekty:</span>
      <Dropdown>
        <Dropdown.Toggle
          variant={selectedStatus.variant}
          disabled={disabled}
          className="d-flex align-items-center gap-2"
          style={{ width: '180px', justifyContent: 'space-between' }}
        >
          <div className="d-flex align-items-center gap-2">
            <StatusIcon size={16} />
            {selectedStatus.label}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {STATUSES.map((statusOption) => {
            const StatusOptionIcon = statusOption.icon;
            return (
              <Dropdown.Item
                key={statusOption.value}
                onClick={() => onChange(statusOption.value)}
                className={`d-flex align-items-center gap-2 text-${statusOption.variant}`}
              >
                <StatusOptionIcon size={16} />
                {statusOption.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ProofreadingStatus;
